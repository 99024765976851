window.translations = {
	"are_you_sure?": {
		en: "Are you sure?",
		cn: "您确定吗？",
		es: "¿Está seguro?",
		pt: "Você tem certeza?",
		ru: "Вы уверены?"
	},
	careers: {
		en: "Careers",
		cn: "招贤纳士",
		es: "Carreras",
		pt: "Carreiras",
		ru: "Работа в компании"
	},
	general_dynamics: {
		en: "General Dynamics",
		cn: "General Dynamics",
		es: "General Dynamics",
		pt: "General Dynamics",
		ru: "General Dynamics"
	},
	"gulfstream.com": {
		en: "Gulfstream.com",
		cn: "Gulfstream.com",
		es: "Gulfstream.com",
		pt: "Gulfstream.com",
		ru: "Gulfstream.com"
	},
	california_supply_chains_act: {
		en: "California Supply Chains Act",
		cn: "加州供应链法案",
		es: "Ley de Cadenas de Suministro de California",
		pt: "Lei de transparência nas cadeias de suprimento da Califórnia (California Supply Chains Act)",
		ru: "Закон штата Калифорния о цепочках поставок"
	},
	privacy_notice: {
		en: "Privacy Notice",
		cn: "隐私声明",
		es: "Aviso de privacidad",
		pt: "Aviso de privacidade",
		ru: "Уведомление о конфиденциальности"
	},
	legal_and_policies: {
		en: "Legal and Policies",
		cn: "法律和政策",
		es: "Legal y políticas",
		pt: "Regras e políticas",
		ru: "Юридическая информация и политики"
	},
	investor_relations: {
		en: "Investor Relations",
		cn: "投资者关系",
		es: "Relaciones con inversionistas",
		pt: "Relações com investidores ",
		ru: "Отношения с инвесторами"
	},
	terms_of_use: {
		en: "Terms of Use",
		cn: "使用条款",
		es: "Términos y condiciones de uso",
		pt: "Termos de uso",
		ru: "Условия использования"
	},
	footer_disclaimer: {
		en: "Some of the equipment described or depicted is optional. Please contact Gulfstream with any inquiries involving aircraft capabilities and equipment. In the event of a conflict, dispute or discrepancy between the English language version of this webpage and the non-English language version, the English language version shall prevail.",
		cn: "本网站所描述的某些设备为选装。若对飞机性能和设备有任何疑问，请与Gulfstream联系。如果本网页的英文版本与非英文版本存在矛盾、争议或差异，请以英文版本为准。",
		es: "Algunos de los equipos que se describen o se muestran son opcionales. Comuníquese con Gulfstream si tiene alguna duda sobre las características y el equipamiento de la aeronave. En el caso de conflictos, disputas o discrepancias entre la versión en inglés de esta página web y la versión en un idioma distinto del inglés, prevalecerá la versión en inglés.",
		pt: "Alguns equipamentos descritos ou mostrados são opcionais. Entre em contato com a Gulfstream com quaisquer perguntas sobre as capacidades das aeronaves e equipamentos. No evento de um conflito, disputa ou discrepância entre a versão do idioma inglês desta página da web e a versão em outro idioma que não seja o inglês, a versão do idioma inglês prevalecerá.",
		ru: "Некоторое из описанного или изображенного оборудования является дополнительным. Обращайтесь в компанию Gulfstream с любыми вопросами по характеристикам и комплектации самолетов. Если содержание данной веб-страницы на английском языке отличается от содержания веб-страницы на любом другом языке или противоречит ему, версия на английском языке имеет преимущественную силу."
	},
	our_history: {
		en: "Our History",
		cn: "我们的历史",
		es: "Nuestra historia",
		pt: "Nossa história",
		ru: "Наша история"
	},
	corporate_responsibility: {
		en: "Corporate Responsibility",
		cn: "企业责任",
		es: "Responsabilidad corporativa",
		pt: "Responsabilidade corporativa",
		ru: "Корпоративная ответственность"
	},
	gulfstream_news: {
		en: "Gulfstream News",
		cn: "Gulfstream资讯",
		es: "Noticias de Gulfstream",
		pt: "Notícias sobre a Gulfstream",
		ru: "Новости Gulfstream"
	},
	all_news_releases: {
		en: "All News Releases",
		cn: "所有资讯文章",
		es: "Todos los comunicados de prensa",
		pt: "Todos os comunicados de imprensa",
		ru: "Все выпуски новостей"
	},
	all_rights_reserved: {
		en: "All rights reserved.",
		cn: "保留所有权利。",
		es: "Todos los derechos reservados.",
		pt: "Todos os direitos reservados.",
		ru: "Все права защищены."
	},
	dark_page_banner: {
		en: `For all media inquiries, please contact <a href="mailto:media.relations@gulfstream.com">media.relations@gulfstream.com</a>`,
		cn: '如有媒体方面的询问，请发送电子邮件至 <a href="mailto:media.relations@gulfstream.com">media.relations@gulfstream.com</a>',
		es: 'Para toda consulta relacionada con medios, comuníquese con <a href="mailto:media.relations@gulfstream.com">media.relations@gulfstream.com</a>',
		pt: 'Para todas as perguntas sobre a mídia, contate <a href="mailto:media.relations@gulfstream.com">media.relations@gulfstream.com</a>',
		ru: 'По всем вопросам для СМИ обращайтесь по данному адресу: <a href="mailto:media.relations@gulfstream.com">media.relations@gulfstream.com</a>'
	},
	latest_information: {
		en: "Latest Information",
		cn: "最新信息",
		es: "Información más reciente",
		pt: "Informações mais recentes",
		ru: "Последняя информация"
	},
	filter_by: {
		en: "Filter by",
		cn: "筛选条件",
		es: "Filtrar por",
		pt: "Filtrar por",
		ru: "Фильтровать по"
	},
	media_contacts: {
		en: "Media Contacts",
		cn: "媒体联系",
		es: "Contacto con los Medios",
		pt: "Contatos de mídia",
		ru: "Контакты для СМИ"
	},
	"non-media_image_queries": {
		en: "Non-Media Image Queries",
		cn: "非媒体图像查询",
		es: "Preguntas sobre imágenes no multimedia",
		pt: "Perguntas sobre imagens que não são para a mídia",
		ru: "Запросы, не относящиеся к мультимедийным изображениям"
	},
	trademark_popup: {
		en: "Thank you for your inquiry. Gulfstream trademarks, logos, images, videos and advertising, or similar materials, are legally protected intellectual property. Gulfstream does not grant permission to use our intellectual property for commercial purposes.",
		cn: "感谢您的咨询。Gulfstream的商标、徽标、图像、视频和广告或类似材料是Gulfstream的知识产权，且受到法律的保护。未经Gulfstream准许，不得将Gulfstream的知识产权用于商业目的。",
		es: "Gracias por su pregunta. Las marcas, los logotipos, las imágenes, los videos y la publicidad, o elementos similares, de Gulfstream son propiedad intelectual protegida legalmente. Gulfstream no permite que se use la propiedad intelectual de la empresa para fines comerciales.",
		pt: "Obrigado pelo seu contato. Publicidade, vídeos, imagens, logotipos, e marcas registradas da Gulfstream ou materiais semelhantes são propriedade intelectual protegida por leis. A Gulfstream não concede licença para o uso da sua propriedade intelectual para fins comerciais.",
		ru: "Благодарим за ваш запрос. Товарные знаки, логотипы, изображения, видео, реклама или подобные материалы Gulfstream являются интеллектуальной собственностью, охраняемой законом. Gulfstream не дает разрешения на использование нашей интеллектуальной собственности в коммерческих целях."
	},
	kilobytes: {
		en: "K",
		cn: "K",
		es: "K",
		pt: "K",
		ru: "К"
	},
	megabytes: {
		en: "MB",
		cn: "MB",
		es: "MB",
		pt: "MB",
		ru: "МБ"
	},
	newsroom: {
		en: "Newsroom",
		cn: "媒体发布",
		es: "Sala de prensa",
		pt: "Sala de imprensa",
		ru: "Новости"
	},
	multimedia_center: {
		en: "Multimedia Center",
		cn: "多媒体中心",
		es: "Centro multimedia",
		pt: "Centro de multimídia",
		ru: "Мультимедийный центр"
	},
	gulfstream_newsroom_orders: {
		en: "Gulfstream Newsroom Orders",
		cn: "湾流新闻室订单",
		es: "Pedidos de la sala de prensa de Gulfstream",
		pt: "Pedidos de sala de imprensa da Gulfstream",
		ru: "Заказы на новости Gulfstream"
	},
	visit_the_multimedia_center_to_add_assets_to_your_collection: {
		en: "Visit the Multimedia Center to add assets to your collection",
		cn: "访问“多媒体中心”，添加资产至您的收藏",
		es: "Visite el Centro multimedia para agregar activos a su colección",
		pt: "Visite o Centro de Multimídia para acrescentar ativos à sua coleção",
		ru: "Посетите мультимедийный центр, чтобы пополнить свою коллекцию ресурсов"
	},
	"there_are_currently_no_assets_in_your_cart.": {
		en: "There are currently no assets in your cart.",
		cn: "目前您的购物车中没有资产。",
		es: "Actualmente no hay activos en su canasta.",
		pt: "Não há nenhum ativo em seu carrinho.",
		ru: "В настоящий момент в вашей корзине нет ресурсов."
	},
	download_overview: {
		en: "Download Overview",
		cn: "下载概览",
		es: "Descripción general de descargas",
		pt: "Visão geral do download",
		ru: "Загрузить обзор"
	},
	view_requested_downloads: {
		en: "View Requested Downloads",
		cn: "查看请求的下载",
		es: "Ver las descargas solicitadas",
		pt: "Ver downloads solicitados",
		ru: "Просмотреть запрошенные загрузки"
	},
	view: {
		en: "View",
		cn: "查看",
		es: "Ver",
		pt: "Visualizar",
		ru: "Просмотр"
	},
	about: {
		en: "About",
		cn: "关于",
		es: "Acerca de",
		pt: "Sobre",
		ru: "О нас"
	},
	language: {
		en: "Language",
		cn: "语言",
		es: "Idioma",
		pt: "Idioma",
		ru: "Язык"
	},
	separator: {
		en: ",",
		cn: "，",
		es: ".",
		pt: ".",
		ru: " "
	},
	decimal: {
		en: ".",
		cn: ".",
		es: ",",
		pt: ",",
		ru: ","
	},
	thank_you: {
		en: "Thank You",
		cn: "谢谢！",
		es: "Gracias",
		pt: "Obrigado",
		ru: "Спасибо"
	},
	"your_request_has_been_submitted._check_your_inbox_for_a_confirmation_email_with_more_information.": {
		en: "Your request has been submitted. Check your inbox for a confirmation email with more information.",
		cn: "您的请求已提交。在收件箱中查看确认电邮，了解更多信息。",
		es: "Su solicitud se ha enviado. Revise su bandeja de entrada para comprobar que recibió un correo de confirmación con más información.",
		pt: "Seu pedido foi enviado. Procure em sua caixa de entrada um e-mail de confirmação com mais informações.",
		ru: "Ваш запрос отправлен. Для получения дополнительной информации ознакомьтесь с подтверждением, отправленным по электронной почте."
	},
	"this_email_confirms_your_recent_request_for_gulfstream_media_assets.": {
		en: "This email confirms your recent request for Gulfstream media assets.",
		cn: "本邮件旨在确认您近期的湾流媒体资产请求。",
		es: "Este correo electrónico confirma su solicitud reciente de activos de medios de Gulfstream.",
		pt: "Este e-mail confirma seu pedido recente de ativos de mídia da Gulfstream.",
		ru: "Это сообщение содержит подтверждение вашего запроса на получение файлов мультимедиа Gulfstream."
	},
	showing: {
		en: "Showing",
		cn: "显示",
		es: "Mostrando",
		pt: "Mostrando",
		ru: "Отображение"
	},
	of: {
		en: "of",
		cn: "/",
		es: "de",
		pt: "de",
		ru: "из"
	},
	item: {
		en: "item",
		cn: "项目",
		es: "elemento",
		pt: "item",
		ru: "элемент"
	},
	items: {
		en: "items",
		cn: "项目",
		es: "elementos",
		pt: "itens",
		ru: "позиций"
	},
	share: {
		en: "Share",
		cn: "分享",
		es: "Compartir",
		pt: "Compartilhar",
		ru: "Поделиться"
	},
	news_releases: {
		en: "News Releases",
		cn: "新闻稿",
		es: "Comunicados de prensa",
		pt: "Comunicados à imprensa",
		ru: "Выпуски новостей"
	},
	related_news_releases: {
		en: "Related News Releases",
		cn: "相关新闻稿",
		es: "Comunicados de prensa relacionados",
		pt: "Comunicados à imprensa relacionados",
		ru: "Соответствующие выпуски новостей"
	},
	latest_news_releases: {
		en: "Latest News Releases",
		cn: "最新新闻稿",
		es: "Comunicados de prensa más recientes",
		pt: "Comunicados à imprensa mais recentes",
		ru: "Последние выпуски новостей"
	},
	read_news_release: {
		en: "Read News Release",
		cn: "查看新闻稿",
		es: "Leer comunicado de prensa",
		pt: "Ler Comunicado à imprensa",
		ru: "Читать выпуск новостей"
	},
	media_kits: {
		en: "Media Kits",
		cn: "媒体工具包",
		es: "Kits de medios",
		pt: "Kits de mídia",
		ru: "Информационная подборка для прессы"
	},
	back_to_media_kits: {
		en: "Back to Media Kits",
		cn: "返回媒体工具包",
		es: "Regresar a kits de medios",
		pt: "Retornar aos kits de mídia",
		ru: "Назад к информационной подборке для прессы"
	},
	backgrounders_and_fact_sheets: {
		en: "Backgrounders and Fact Sheets",
		cn: "背景资料和情况介绍",
		es: "Hojas informativas y hojas de datos",
		pt: "Históricos e Fichas de dados",
		ru: "Справочные материалы и информационные бюллетени"
	},
	order_id: {
		en: "Order ID",
		cn: "订单ID",
		es: "ID del pedido",
		pt: "ID do pedido",
		ru: "Идентификатор заказа"
	},
	asset_request: {
		en: "Asset Request",
		cn: "资产请求",
		es: "Solicitud de activo",
		pt: "Solicitação de ativo",
		ru: "Запрос актива"
	},
	request_details: {
		en: "Request Details",
		cn: "请求详情",
		es: "Detalles de la solicitud",
		pt: "Detalhes do pedido",
		ru: "Детали запроса"
	},
	requested: {
		en: "Requested",
		cn: "请求的",
		es: "Solicitado",
		pt: "Solicitado",
		ru: "апрошено"
	},
	wanted_by: {
		en: "Wanted By",
		cn: "需要者",
		es: "Pedido por",
		pt: "Procurado por",
		ru: "Необходимо к"
	},
	status: {
		en: "Status",
		cn: "状态",
		es: "Estado",
		pt: "Status",
		ru: "Статус"
	},
	at: {
		en: "at",
		cn: "在",
		es: "en",
		pt: "em",
		ru: "в"
	},
	usage: {
		en: "Usage",
		cn: "使用",
		es: "Uso",
		pt: "Uso",
		ru: "Использование"
	},
	your_order: {
		en: "Your Order",
		cn: "您的订单",
		es: "Su pedido",
		pt: "Sua ordem",
		ru: "Ваш заказ"
	},
	your_details: {
		en: "Your Details",
		cn: "您的详细资料",
		es: "Sus detalles",
		pt: "Seus detalhes",
		ru: "Ваши данные"
	},
	"you_will_be_notified_when_your_request_has_been_processed._you_can_visit_this_link_to_check_on_it_in_the_meantime": {
		en: "You will be notified when your request has been processed. You can visit this link to check on it in the meantime",
		cn: "请求被处理后，您会收到通知。同时您可以访问此链接进行查看",
		es: "Se le notificará cuando se haya procesado su solicitud. Mientras tanto, puede visitar este enlace para hacer un seguimiento del progreso",
		pt: "Você será notificado quando o seu pedido for processado. Enquanto isso, você pode verificar neste link",
		ru: "После обработки запроса вы получите уведомление. Для проверки статуса вы можете перейти по этой ссылке"
	},
	email_address: {
		en: "Email Address",
		cn: "电子邮件地址",
		es: "Dirección de correo electrónico",
		pt: "Endereço de e-mail",
		ru: "Адрес электронной почты"
	},
	name: {
		en: "Name",
		cn: "名字",
		es: "Nombre",
		pt: "Nome",
		ru: "имя"
	},
	first_name: {
		en: "First Name",
		cn: "名字",
		es: "Primer nombre",
		pt: "Primeiro nome",
		ru: "Имя"
	},
	last_name: {
		en: "Last Name",
		cn: "名",
		es: "Apellido",
		pt: "Sobrenome",
		ru: "Фамилия"
	},
	company_name: {
		en: "Company Name",
		cn: "公司名称",
		es: "Nombre de la empresa",
		pt: "Nome da empresa",
		ru: "Наименование компании"
	},
	website: {
		en: "website",
		cn: "网站",
		es: "sitio web",
		pt: "local na rede Internet",
		ru: "Веб-сайт"
	},
	company_website: {
		en: "Company Website",
		cn: "公司网址",
		es: "Sitio web de la empresa",
		pt: "Website da empresa",
		ru: "Веб-сайт компании"
	},
	phone_number: {
		en: "Phone Number",
		cn: "电话号码",
		es: "Número telefónico",
		pt: "Telefone",
		ru: "Номер телефона"
	},
	city: {
		en: "City",
		cn: "城市",
		es: "Ciudad",
		pt: "Cidade",
		ru: "Город"
	},
	country: {
		en: "Country",
		cn: "国家/地区",
		es: "País",
		pt: "País",
		ru: "Страна"
	},
	none_provided: {
		en: "None provided",
		cn: "没有提供",
		es: "Ninguno proporcionado",
		pt: "nenhum fornecido",
		ru: "не предоставлен"
	},
	due_date_for_assets: {
		en: "Due Date For Assets",
		cn: "资产到期日",
		es: "Fecha límite para los activos",
		pt: "Data de vencimento para ativos",
		ru: "Крайний срок по ресурсам"
	},
	"provide_a_detailed_description_of_how_gulfstream’s_assets_are_to_be_used": {
		en: "Provide a detailed description of how Gulfstream's assets are to be used",
		cn: "详细说明您如何计划使用Gulfstream资产",
		es: "Proporcione una descripción detallada de cómo se usarán los activos de Gulfstream.",
		pt: "Fornecer uma descrição detalhada de como os ativos da Gulfstream são usados",
		ru: "Предоставьте подробное описание того, как будут использоваться ресурсы Gulfstream"
	},
	order_disclaimer: {
		en: '<p>You may be granted access to download your requested items once you submit the information above and Gulfstream has reviewed it. To streamline the approval process, please provide any related information (story focus, drafts, etc.) with your request. You will be notified by email when the review is complete.</p><p>The photography and video available on this site may be used for editorial purposes only. They cannot be used for any other purposes, including but not limited to, advertising or trade promotions. These assets are provided for your specific use only and should not be provided to a third party.</p><p>Any questions regarding this process should be addressed to <a class="underline" href="mailto:media.requests@gulfstream.com">media.requests@gulfstream.com</a>.</p><p><span id="modal_trademark_modal_link" class="underline pointer">Non-Media Image Queries</span></p>',
		cn: '<p>在您提交上述信息并且湾流公司已审核该信息后，可能准许您下载您所请求的项目。为了简化审批流程，请在提交您的申请时提供任何相关信息（叙事重点、草稿，等等）。审核完成后，您会收到电子邮件通知。</p><p>本网站提供的照片和视频只可用于媒体编辑用途。它们不得用于任何其他用途（包括但不限于广告或促销）。这些资产仅供您使用，不得提供给第三方。</p><p>如有任何关于此流程的疑问，请发送电子邮件至 <a class="underline" href="mailto:media.requests@gulfstream.com">media.requests@gulfstream.com</a>。</p><p><span id="modal_trademark_modal_link" class="underline pointer">非媒体图像查询</span></p>',
		es: '<p>Se le podrá conceder el acceso para descargar los elementos solicitados después de que haya provisto la información anterior y Gulfstream la haya revisado. Para que el proceso de aprobación sea más eficiente, proporcione toda la información (enfoque del artículo, borradores, etc.) relacionada con su solicitud. Se le informará por correo electrónico cuando haya concluido la revisión.</p><p>Las fotografías y los videos disponibles en este sitio solo podrán usarse para fines editoriales. No podrán emplearse para otros fines, como serían publicidad o promociones comerciales, entre otros. Estos activos se proveen exclusivamente para su uso específico y no deberán entregarse a terceros.</p><p>Toda pregunta relacionada con este proceso deberá dirigirse a <a class="underline" href="mailto:media.requests@gulfstream.com">media.requests@gulfstream.com</a>.</p><p><span id="modal_trademark_modal_link" class="underline pointer">Preguntas sobre imágenes no multimedia</span></p>',
		pt: '<p>Você poderá receber o acesso para fazer o download de seus itens solicitados assim que submeter a informação acima e após conclusão da revisão pela Gulfstream. Para simplificar o processo de aprovação, favor fornecer quaisquer informações relacionadas (foco da história, esboços, etc.) juntamente com a sua solicitação. Você poderá ser notificado por e-mail quando a revisão estiver concluída.</p><p>A fotografia e o vídeo disponíveis neste site poderão ser usados apenas para finalidades editoriais. Eles não poderão ser usados para nenhuma outra finalidade, incluindo mas não limitada a publicidade ou promoções comerciais. Esses ativos são fornecidos apenas para seu uso específico e não devem ser oferecidos a terceiros.</p><p>Quaisquer perguntas sobre este processo deverão ser encaminhadas para <a class="underline" href="mailto:media.requests@gulfstream.com">media.requests@gulfstream.com</a>.</p><p><span id="modal_trademark_modal_link" class="underline pointer">Pergunta sobre imagens que não são para mídia</span></p>',
		ru: '<p>Вам может быть предоставлен доступ для загрузки запрошенных вами позиций после того, как вы отправите вышеуказанную информацию, а Gulfstream ее рассмотрит. Чтобы упростить процесс утверждения, предоставьте вместе со своим запросом любую связанную информацию (сюжет, черновики и т. д.). Когда проверка будет завершена, вы получите уведомление по электронной почте.</p><p>Фотографии и видео, доступные на этом сайте, могут быть использованы только в редакционных целях. Их нельзя использовать для каких-либо других целей, включая, помимо прочего, рекламу или торговые акции. Эти ресурсы предназначены для конкретного использования только вами и не должны передаваться третьим лицам.</p><p>Любые вопросы, связанные с этим процессом, следует отправлять по данному адресу: <a class="underline" href="mailto:media.requests@gulfstream.com">media.requests@gulfstream.com</a>.</p><p><span id="modal_trademark_modal_link" class="underline pointer">Запросы, не относящиеся к мультимедийным изображениям</span></p>'
	},
	submitting_your_order: {
		en: "Submitting your order",
		cn: "提交您的订单",
		es: "Envío de su pedido",
		pt: "Enviando seu pedido",
		ru: "Отправка вашего заказа"
	},
	"there_was_a_problem_with_the_form._please_try_again.": {
		en: "There was a problem with the form. Please try again.",
		cn: "表格有问题。请重试。",
		es: "Hubo un problema con el formulario. Inténtelo de nuevo.",
		pt: "Há um problema com o formulário. Favor tentar novamente.",
		ru: "Возникла проблема с формой. Попробуйте еще раз."
	},
	"your_submission_was_successful.": {
		en: "Your submission was successful",
		cn: "您已成功提交。",
		es: "Su solicitud fue enviada con éxito",
		pt: "Sua submissão foi bem-sucedida",
		ru: "Поданные вами материалы успешно отправлены"
	},
	your_gulfstream_media_assets_request: {
		en: "Your Gulfstream Media Assets Request",
		cn: "您的 Gulfstream 媒体资产请求",
		es: "Su solicitud de activos de medios de Gulfstream",
		pt: "Seu pedido de ativos de mídia da Gulfstream ",
		ru: "Ваш запрос медиаресурсов Gulfstream"
	},
	file_size: {
		en: "File Size",
		cn: "文件大小",
		es: "Tamaño de archivo",
		pt: "Tamanho de arquivo",
		ru: "Размер файла"
	},
	select: {
		en: "Select",
		cn: "选择",
		es: "Seleccionar",
		pt: "Selecionar",
		ru: "Выбрать"
	},
	remove: {
		en: "Remove",
		cn: "删除",
		es: "Eliminar",
		pt: "Remover",
		ru: "Удалить"
	},
	submit: {
		en: "Submit",
		cn: "提交",
		es: "Enviar",
		pt: "Submeter",
		ru: "Отправить"
	},
	submit_request: {
		en: "Submit Request",
		cn: "提交请求",
		es: "Enviar solicitud",
		pt: "Submeter solicitação",
		ru: "Подать запрос"
	},
	required_field: {
		en: "Required Field",
		cn: "必填项目",
		es: "Campo requerido",
		pt: "Campo obrigatório",
		ru: "Обязательное поле"
	},
	include_protocol: {
		en: "Include protocol (eg, http:// or https://)",
		cn: "包括协议（例如，http://或https://）",
		es: "Incluir protocolo (p. ej., http:// o https://)",
		pt: "Incluir protocolo (p.ex., http:// ou https://)",
		ru: "Укажите протокол (например, http:// или https://)"
	},
	"we_could_not_find_that_news_release.": {
		en: "We could not find that news release.",
		cn: "我们找不到那篇新闻稿。",
		es: "No pudimos encontrar ese comunicado de prensa.",
		pt: "Não podemos encontrar esse comunicado à imprensa.",
		ru: "Мы не смогли найти этот выпуск новостей."
	},
	error: {
		en: "There was an error. Please try again.",
		cn: "出现错误。",
		es: "Hubo un error.",
		pt: "Há um erro.",
		ru: "Произошла ошибка."
	},
	"looks_like_we_will_need_to_reroute_you.": {
		en: "Looks like we will need to reroute you.",
		cn: "页面未找到。",
		es: "Parece que tenemos que reenrutarlo.",
		pt: "Parece que teremos que redirecioná-lo.",
		ru: "Похоже, нам придется перенаправить вас."
	},
	return_to_homepage: {
		en: "Return to Homepage",
		cn: "返回主页",
		es: "Regresar a la página de inicio",
		pt: "Retornar à Página inicial",
		ru: "Вернуться на главную страницу"
	},
	back: {
		en: "Back",
		cn: "返回",
		es: "Atrás",
		pt: "Voltar",
		ru: "Назад"
	},
	next: {
		en: "Next",
		cn: "下一个",
		es: "Siguiente",
		pt: "Próximo",
		ru: "Далее"
	},
	welcome: {
		en: "Welcome,",
		cn: "欢迎",
		es: "Bienvenido(a),",
		pt: "Bem-vindo,",
		ru: "Добро пожаловать,"
	},
	categories: {
		en: "Categories",
		cn: "类别",
		es: "Categorías",
		pt: "Categorias",
		ru: "Категории"
	},
	read_more: {
		en: "Read More",
		cn: "了解更多",
		es: "Más información",
		pt: "Ler mais",
		ru: "Читать далее"
	},
	"could_not_log_you_in._please_try_again.": {
		en: "Could not log you in. Please try again.",
		cn: "无法登录。请重试。",
		es: "No pudo iniciarse su sesión. Inténtelo de nuevo.",
		pt: "Não pode fazer o seu login. Favor tentar novamente.",
		ru: "Не удалось войти в систему. Попробуйте еще раз."
	},
	"there_was_an_error_retrieving_media_kits.": {
		en: "There was an error retrieving media kits.",
		cn: "检索媒体工具包时出错。",
		es: "Hubo un error al obtener los kits de medios.",
		pt: "Há um erro na recuperação de kits de mídia.",
		ru: "При получении информационной подборки для прессы произошла ошибка."
	},
	"there_was_an_error_retrieving_media_assets.": {
		en: "There was an error retrieving media assets.",
		cn: "检索媒体资产时出错。",
		es: "Hubo un error al obtener los activos de medios.",
		pt: "Há um erro na recuperação de ativos de mídia.",
		ru: "При получении файлов мультимедиа произошла ошибка."
	},
	"there_was_an_error_retrieving_news_release_data.": {
		en: "There was an error retrieving news release data.",
		cn: "检索新闻发布数据时出错。",
		es: "Hubo un error al obtener los datos del comunicado de prensa.",
		pt: "Há um erro na recuperação de dados de comunicados à imprensa.",
		ru: "При получении данных выпуска новостей произошла ошибка."
	},
	"there_was_an_error_retrieving_categories.": {
		en: "There was an error retrieving categories.",
		cn: "检索类别时出错。",
		es: "Hubo un error al obtener las categorías.",
		pt: "Há um erro na recuperação de categorias.",
		ru: "При получении категорий произошла ошибка."
	},
	aircraft: {
		en: "Aircraft",
		cn: "飞机",
		es: "Aeronave",
		pt: "Aeronaves",
		ru: "Самолеты"
	},
	events: {
		en: "Events",
		cn: "活动 ",
		es: "Eventos",
		pt: "Eventos",
		ru: "События"
	},
	product_support: {
		en: "Customer Support",
		cn: "客户支持",
		es: "Soporte al cliente",
		pt: "Suporte ao cliente",
		ru: "Поддержка клиентов"
	},
	customer_support: {
		en: "Customer Support",
		cn: "客户支持",
		es: "Soporte al cliente",
		pt: "Suporte ao cliente",
		ru: "Поддержка клиентов"
	},
	facilities: {
		en: "Facilities",
		cn: "设施",
		es: "Instalaciones",
		pt: "Instalações",
		ru: "Объекты"
	},
	sites: {
		en: "Sites",
		cn: "地址",
		es: "Sitios",
		pt: "Locais",
		ru: "Сайты"
	},
	other_categories: {
		en: "Other Categories",
		cn: "其他类别",
		es: "Otras categorías",
		pt: "Outras categorias",
		ru: "Прочие категории"
	},
	newsroomcategory: {
		en: "Other Categories",
		cn: "其他类别",
		es: "Otras categorías",
		pt: "Outras categorias",
		ru: "Прочие категории"
	},
	newsroom_category: {
		en: "Other Categories",
		cn: "其他类别",
		es: "Otras categorías",
		pt: "Outras categorias",
		ru: "Прочие категории"
	},
	category: {
		en: "Category",
		cn: "类别",
		es: "Categoría",
		pt: "Categoria",
		ru: "Категория"
	},
	subcategory: {
		en: "Subcategory",
		cn: "子类别",
		es: "Subcategoría",
		pt: "Subcategoria",
		ru: "Подкатегория"
	},
	asset_type: {
		en: "Asset Type",
		cn: "资产类型",
		es: "Tipo de activo",
		pt: "Tipo de ativo",
		ru: "Тип актива"
	},
	unknown: {
		en: "Unknown",
		cn: "未知",
		es: "Desconocido",
		pt: "Desconhecido",
		ru: "Неизвестно"
	},
	video: {
		en: "video",
		cn: "视频",
		es: "video",
		pt: "vídeo",
		ru: "видео"
	},
	seconds: {
		en: "seconds",
		cn: "秒",
		es: "segundos",
		pt: "segundos",
		ru: "секунд"
	},
	image: {
		en: "image",
		cn: "图片",
		es: "imagen",
		pt: "imagem",
		ru: "изображение"
	},
	pdf: {
		en: "PDF",
		cn: "PDF",
		es: "PDF",
		pt: "PDF",
		ru: "PDF-файл"
	},
	download: {
		en: "Download",
		cn: "下载",
		es: "Descargar",
		pt: "Baixar",
		ru: "Скачать"
	},
	view_kit: {
		en: "View Kit",
		cn: "查看媒体工具包",
		es: "Ver kit",
		pt: "Visualizar o kit",
		ru: "Просмотреть пакет"
	},
	download_pdf: {
		en: "Download PDF",
		cn: "下载PDF",
		es: "Descargar PDF",
		pt: "Baixar o PDF",
		ru: "Скачать PDF"
	},
	"download_high-resolution_photo": {
		en: "Download high-resolution photo",
		cn: "下载高清图片",
		es: "Descargar fotografía de alta resolución",
		pt: "Baixar foto de alta resolução",
		ru: "Скачать качественную фотографию"
	},
	download_video: {
		en: "Download video",
		cn: "下载视频",
		es: "Descargar video",
		pt: "Baixar video",
		ru: "Скачать видео"
	},
	download_unavailable: {
		en: "Download unavailable",
		cn: "下载不可用",
		es: "Descarga no disponible",
		pt: "Download não disponível",
		ru: "Скачивание недоступно"
	},
	this_content_currently_is_available_in_english_only: {
		en: "This content currently is available in English only",
		cn: "该内容目前仅以英语提供",
		es: "Actualmente, este contenido solo está disponible en inglés",
		pt: "Atualmente, este conteúdo está disponível apenas em inglês",
		ru: "Этот контент в настоящее время доступен только на английском языке"
	},
	months: {
		en: {
			0: "January",
			1: "February",
			2: "March",
			3: "April",
			4: "May",
			5: "June",
			6: "July",
			7: "August",
			8: "September",
			9: "October",
			10: "November",
			11: "December"
		},
		cn: {
			0: "1",
			1: "2",
			2: "3",
			3: "4",
			4: "5",
			5: "6",
			6: "7",
			7: "8",
			8: "9",
			9: "10",
			10: "11",
			11: "12"
		},
		es: {
			0: "enero",
			1: "febrero",
			2: "marzo",
			3: "abril",
			4: "mayo",
			5: "junio",
			6: "julio",
			7: "agosto",
			8: "septiembre",
			9: "octubre",
			10: "noviembre",
			11: "diciembre"
		},
		pt: {
			0: "janeiro",
			1: "fevereiro",
			2: "março",
			3: "abril",
			4: "maio",
			5: "junho",
			6: "julho",
			7: "agosto",
			8: "setembro",
			9: "outubro",
			10: "novembro",
			11: "dezembro"
		},
		ru: {
			0: "января",
			1: "февраля",
			2: "марта",
			3: "апреля",
			4: "мая",
			5: "июня",
			6: "июля",
			7: "августа",
			8: "сентября",
			9: "октября",
			10: "ноября",
			11: "декабря",
			// months when date included

			12: "январь",
			13: "февраль",
			14: "март",
			15: "апреля",
			16: "май",
			17: "июнь",
			18: "июль",
			19: "август",
			20: "сентябрь",
			21: "октябрь",
			22: "ноябрь",
			23: "Декабрь"
			// months with no date
		}
	},
	date_format: {
		en: "{m} {d}, {y}",
		cn: "{y}年{m}月{d}日",
		es: "{d} de {m} de {y}",
		pt: "{d} de {m} de {y}",
		ru: "{d} {m} {y} г."
	},
	date_format_year_month: {
		en: "{m} {y}",
		cn: "{y}年{m}月",
		es: "{m} de {y}",
		pt: "{m} de {y}",
		ru: "{m} {y} г."
	},
	date_format_year: {
		en: "{y}",
		cn: "{y}年",
		es: "{y}",
		pt: "{y}",
		ru: "{y} г."
	},
	"savannah,_ga.": {
		en: "Savannah, Ga.",
		cn: "乔治亚州萨凡纳",
		es: "Savannah, Georgia",
		pt: "Savannah, Geórgia (USA)",
		ru: "САВАННА, штат Джорджия"
	},
	search_the_site: {
		en: "Search the Site",
		cn: "搜索网站",
		es: "Buscar en el sitio",
		pt: "Buscar no site",
		ru: "Поиск по сайту"
	},
	"no_search_information_was_provided.": {
		en: "No search information was provided.",
		cn: "未提供搜索信息。",
		es: "No se proporcionó información para la búsqueda.",
		pt: "Nenhuma informação de busca foi fornecida.",
		ru: "Не заданы условия поиска."
	},
	"there_were_no_results.": {
		en: "There were no results.",
		cn: "没有结果。",
		es: "No se encontraron resultados.",
		pt: "Não houve nenhum resultado.",
		ru: "Результатов нет."
	},
	search: {
		en: "Search",
		cn: "搜索",
		es: "Buscar",
		pt: "Buscar",
		ru: "Поиск"
	},
	clear: {
		en: "Clear",
		cn: "清除",
		es: "Borrar",
		pt: "Limpar",
		ru: "Очистить"
	},
	keywords: {
		en: "Keywords",
		cn: "关键词",
		es: "Palabras clave",
		pt: "Palavras-chaves",
		ru: "Ключевые слова"
	},
	search_results: {
		en: "Search Results",
		cn: "搜索结果",
		es: "Resultados de la búsqueda",
		pt: "Buscar resultados",
		ru: "Результаты поиска"
	},
	year: {
		en: "Year",
		cn: "年",
		es: "Año",
		pt: "Ano",
		ru: "Год"
	},
	models: {
		en: "Models",
		cn: "模型",
		es: "Modelos",
		pt: "Modelos",
		ru: "Модели"
	},
	"nbaa-bace": {
		en: "NBAA-BACE",
		cn: "NBAA-BACE",
		es: "NBAA-BACE",
		pt: "NBAA-BACE",
		ru: "NBAA-BACE"
	},
	"nbaa_business_aviation_convention_&_exhibition": {
		en: "NBAA Business Aviation Convention & Exhibition",
		cn: "NBAA公务航空大会及展览",
		es: "Convención y Exposición de Aviación Ejecutiva de la NBAA",
		pt: "Convenção e Exibição de Aviação Executiva NBAA",
		ru: "Конференция и выставка бизнес-авиации национальной ассоциации деловой авиации"
	},
	labace: {
		en: "LABACE",
		cn: "LABACE",
		es: "LABACE",
		pt: "LABACE",
		ru: "LABACE"
	},
	paris_air_show: {
		en: "Paris Air Show",
		cn: "巴黎航空展",
		es: "Salón de Aeronáutica de París",
		pt: "Paris Air Show",
		ru: "Авиакосмический салон Париж-Ле-Бурже"
	},
	international_paris_air_show: {
		en: "International Paris Air Show",
		cn: "巴黎国际航空展",
		es: "Salón Internacional de Aeronáutica de París",
		pt: "International Paris Air Show",
		ru: "Международный авиакосмический салон Париж-Ле-Бурже"
	},
	ebace: {
		en: "EBACE",
		cn: "EBACE",
		es: "EBACE",
		pt: "EBACE",
		ru: "EBACE"
	},
	"european_business_aviation_convention_&_exhibition": {
		en: "European Business Aviation Convention & Exhibition",
		cn: "欧洲公务航空大会及展览",
		es: "Convención y Exposición de Aviación Ejecutiva Europea",
		pt: "Convenção e Exibição de Aviação Executiva Europeia",
		ru: "Европейская конференция и выставка деловой авиации"
	},
	mebaa_show: {
		en: "MEBAA Show",
		cn: "MEBAA展",
		es: "Exhibición de la MEBAA",
		pt: "Show MEBAA",
		ru: "Выставка MEBAA"
	},
	abace: {
		en: "ABACE",
		cn: "ABACE",
		es: "ABACE",
		pt: "ABACE",
		ru: "ABACE"
	},
	"asian_business_aviation_conference_&_exhibition": {
		en: "Asian Business Aviation Conference & Exhibition",
		cn: "亚洲公务航空会议及展览",
		es: "Conferencia y Exposición de Aviación Ejecutiva Asiática",
		pt: "Convenção e Exibição de Aviação Executiva Asiática",
		ru: "Азиатская выставка и конференция деловой авиации"
	},
	singapore_airshow: {
		en: "Singapore Airshow",
		cn: "新加坡航展",
		es: "Exhibición Aérea de Singapur",
		pt: "Singapore Airshow",
		ru: "Международный авиасалон в Сингапуре"
	},
	dubai_airshow: {
		en: "Dubai Airshow",
		cn: "迪拜航展",
		es: "Exhibición Aérea de Dubái",
		pt: "Dubai Airshow",
		ru: "Международный авиасалон в Дубае"
	},
	farnborough_international_airshow: {
		en: "Farnborough International Airshow",
		cn: "法恩伯勒国际航展",
		es: "Exhibición Aérea Internacional de Farnborough",
		pt: "Farnborough International Airshow",
		ru: "Международный авиасалон в Фарнборо"
	},
	fast: {
		en: "FAST",
		cn: "FAST",
		es: "FAST",
		pt: "FAST",
		ru: "FAST"
	},
	gulfstream_farnborough_service_center: {
		en: "Gulfstream Farnborough Service Center",
		cn: "Gulfstream法恩伯勒服务中心",
		es: "Centro de Servicios de Gulfstream Farnborough",
		pt: "Centro de serviços da Gulfstream Farnborough",
		ru: "Сервисный центр Gulfstream в Фарнборо"
	},
	gulfstream_palm_beach_service_center: {
		en: "Gulfstream Palm Beach Service Center",
		cn: "Gulfstream棕榈滩服务中心",
		es: "Centro de servicio de Gulfstream en Palm Beach",
		pt: "Centro de serviços da Gulfstream Palm Beach",
		ru: "Сервисный центр Gulfstream в Палм-Бич"
	},
	gulfstream_van_nuys_service_center: {
		en: "Gulfstream Van Nuys Service Center",
		cn: "Gulfstream范奈司服务中心",
		es: "Centro de servicio de Gulfstream en Van Nuys",
		pt: "Centro de serviços da Gulfstream Van Nuys",
		ru: "Сервисный центр Gulfstream в Ван-Найс"
	},
	gulfstream_savannah_service_center_east: {
		en: "Gulfstream Savannah Service Center East",
		cn: "Gulfstream萨凡纳服务中心东",
		es: "Centro de servicio de Gulfstream en Savannah Este",
		pt: "Centro de serviços Leste da Gulfstream Savannah",
		ru: "Сервисный центр Gulfstream в Саванне (Восток)"
	},
	gulfstream_westfield_service_center: {
		en: "Gulfstream Westfield Service Center",
		cn: "Gulfstream西菲尔德服务中心",
		es: "Centro de servicio de Gulfstream en Westfield",
		pt: "Centro de serviços da Gulfstream Westfield",
		ru: "Сервисный центр Gulfstream в Уэстфилде"
	},
	"gulfstream_st._louis_service_center": {
		en: "Gulfstream St. Louis Service Center",
		cn: "Gulfstream圣路易斯服务中心",
		es: "Centro de servicio de Gulfstream en St. Louis",
		pt: "Centro de serviços da Gulfstream St. Louis",
		ru: "Сервисный центр Gulfstream в Сент-Луисе"
	},
	gulfstream_luton_service_center: {
		en: "Gulfstream Luton Service Center",
		cn: "Gulfstream卢顿服务中心",
		es: "Centro de servicio de Gulfstream en Luton",
		pt: "Centro de serviços da Gulfstream Luton",
		ru: "Сервисный центр Gulfstream в Лутоне"
	},
	gulfstream_long_beach_service_center: {
		en: "Gulfstream Long Beach Service Center",
		cn: "Gulfstream长滩服务中心",
		es: "Centro de servicio de Gulfstream en Long Beach",
		pt: "Centro de serviços da Gulfstream Long Beach",
		ru: "Сервисный центр Gulfstream в Лонг-Бич"
	},
	gulfstream_brunswick_service_center: {
		en: "Gulfstream Brunswick Service Center",
		cn: "Gulfstream布仑司维克服务中心",
		es: "Centro de servicio de Gulfstream en Brunswick",
		pt: "Centro de serviços da Gulfstream Brunswick",
		ru: "Сервисный центр Gulfstream в Брансуике"
	},
	gulfstream_beijing_service_center: {
		en: "Gulfstream Beijing Service Center",
		cn: "Gulfstream北京服务中心",
		es: "Centro de servicio de Gulfstream en Pekín",
		pt: "Centro de serviços da Gulfstream Beijing",
		ru: "Сервисный центр Gulfstream в Пекине"
	},
	gulfstream_appleton_service_center: {
		en: "Gulfstream Appleton Service Center",
		cn: "Gulfstream阿普尔顿服务中心",
		es: "Centro de servicio de Gulfstream en Appleton",
		pt: "Centro de serviços da Gulfstream Appleton",
		ru: "Сервисный центр Gulfstream в Эпплтоне"
	},
	manufacturing: {
		en: "Manufacturing",
		cn: "生产制造",
		es: "Manufactura",
		pt: "Fabricação",
		ru: "Производство"
	},
	sales_and_design_center: {
		en: "Sales and Design Center",
		cn: "销售和设计中心",
		es: "Ventas y centro de diseño",
		pt: "Centro de Vendas e Design",
		ru: "Центр продаж и дизайна"
	},
	innovation_and_technology: {
		en: "Innovation and Technology",
		cn: "创新科技",
		es: "Innovación y tecnología",
		pt: "Inovação e Tecnologia",
		ru: "Инновации и технологии"
	},
	interior_design: {
		en: "Interior Design",
		cn: "室内设计",
		es: "Diseño de interiores",
		pt: "Design de interiores",
		ru: "Дизайн интерьера"
	},
	research_and_development: {
		en: "Research and Development",
		cn: "研发",
		es: "Investigación y desarrollo",
		pt: "Pesquisa e Desenvolvimento",
		ru: "Научные исследования и разработки"
	},
	customer_support_facility: {
		en: "Customer Support Facility",
		cn: "客户支持服务中心",
		es: "Instalación de soporte al cliente",
		pt: "Instalação de Suporte ao Cliente",
		ru: "Служба поддержки клиентов"
	},
	savannah: {
		en: "Savannah",
		cn: "萨凡纳",
		es: "Savannah",
		pt: "Savannah",
		ru: "Саванна"
	},
	farnborough: {
		en: "Farnborough",
		cn: "法恩伯勒",
		es: "Farnborough",
		pt: "Farnborough",
		ru: "Фарнборо"
	},
	manhattan: {
		en: "Manhattan",
		cn: "曼哈顿",
		es: "Manhattan",
		pt: "Manhattan",
		ru: "Манхэттен"
	},
	london: {
		en: "London",
		cn: "伦敦",
		es: "Londres",
		pt: "Londres",
		ru: "Лондон"
	},
	long_beach: {
		en: "Long Beach",
		cn: "长滩",
		es: "Long Beach",
		pt: "Long Beach",
		ru: "Лонг-Бич"
	},
	dallas: {
		en: "Dallas",
		cn: "达拉斯",
		es: "Dallas",
		pt: "Dallas",
		ru: "Даллас"
	},
	appleton: {
		en: "Appleton",
		cn: "阿普尔顿",
		es: "Appleton",
		pt: "Appleton",
		ru: "Эпплтон"
	},
	palm_beach: {
		en: "Palm Beach",
		cn: "棕榈滩",
		es: "Palm Beach",
		pt: "Palm Beach",
		ru: "Палм-Бич"
	},
	van_nuys: {
		en: "Van Nuys",
		cn: "范奈司",
		es: "Van Nuys",
		pt: "Van Nuys",
		ru: "Ван-Найс"
	},
	westfield: {
		en: "Westfield",
		cn: "西菲尔德",
		es: "Westfield",
		pt: "Westfield",
		ru: "Уэстфилд"
	},
	"st._louis": {
		en: "St. Louis",
		cn: "圣路易斯",
		es: "St. Louis",
		pt: "St. Louis",
		ru: "Сент-Луис"
	},
	luton: {
		en: "Luton",
		cn: "卢顿",
		es: "Luton",
		pt: "Luton",
		ru: "Лутон"
	},
	brunswick: {
		en: "Brunswick",
		cn: "布仑司维克",
		es: "Brunswick",
		pt: "Brunswick",
		ru: "Брансуик"
	},
	beijing: {
		en: "Beijing",
		cn: "北京",
		es: "Pekín",
		pt: "Pequim",
		ru: "Пекин"
	},
	fleet: {
		en: "Fleet",
		cn: "机队",
		es: "Flota",
		pt: "Frota",
		ru: "Парк"
	},
	together_we_fly: {
		en: "Together We Fly",
		cn: "我们一起齐飞共进",
		es: "Juntos, volamos",
		pt: "Voamos juntos",
		ru: "Партнерство в полете"
	}
};
